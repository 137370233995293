import { render, staticRenderFns } from "./BT-Signature-Overlay.vue?vue&type=template&id=490ec35e&scoped=true&"
import script from "./BT-Signature-Overlay.vue?vue&type=script&lang=js&"
export * from "./BT-Signature-Overlay.vue?vue&type=script&lang=js&"
import style0 from "./BT-Signature-Overlay.vue?vue&type=style&index=0&id=490ec35e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "490ec35e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBottomNavigation,VBtn,VCard,VIcon,VListItem,VListItemContent,VListItemTitle,VOverlay,VProgressCircular,VSlideXTransition,VSpacer,VTextField,VToolbar})
