<template>
    <v-slide-x-transition hide-on-leave>
        <v-btn 
            v-if="!modal" 
            :block="block" 
            @click.stop="open"
            :class="buttonClass"
            :disabled="disabled"
            :icon="icon != null && text == null"
            :large="large"
            :small="small"
            :text="text != null">
            <v-icon 
                v-if="icon != null" 
                :class="buttonClass"
                :large="large"
                :left="text != null"
                :small="small">{{ icon }}
            </v-icon>
            <span :class="buttonClass">{{ text }}</span>
        </v-btn>
        <div v-else>
            <v-overlay :value="modal" :opacity="0.75" z-index="1001">
                <v-card :height="fullscreen ? '100vh' : height" :width="fullscreen ? '100vw' : width" :color="transparent ? 'transparent' : ''">
                    <v-toolbar v-if="showSignerName" class="primary" dark>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Signer Name:</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-content>
                                <v-text-field v-model="mSignerName" hide-details />
                            </v-list-item-content>
                        </v-list-item>
                    </v-toolbar>
                    <VueSignaturePad
                        :height="fullscreen ? '100vh' : height"
                        id="signature"
                        ref="signaturePad"
                        :width="fullscreen ? '100vw' : width"
                        :options="options"
                        :onEnd="update"
                        @onEnd="update" />
                    <v-bottom-navigation
                        grow
                        horizontal
                        absolute>
                        <v-btn 
                            @click.stop="close"
                            class="primary"
                            dark
                            icon
                            key="1"
                            :large="large"
                            :small="small">
                            <v-icon :large="large" :small="small">mdi-arrow-left</v-icon>
                        </v-btn>
                        <v-slide-x-transition hide-on-leave>
                            <v-btn 
                                v-if="canEdit"
                                class="primary"
                                @click.stop="clear"
                                dark
                                icon
                                key="2"
                                :large="large"
                                :small="small">
                                <v-icon :large="large" :small="small">mdi-eraser</v-icon>
                            </v-btn>
                        </v-slide-x-transition>
                        <v-spacer key="3" />
                        <v-slide-x-transition hide-on-leave>
                            <v-btn 
                                v-if="canEdit && isApproved"
                                class="primary"
                                @click.stop="save"
                                dark
                                icon 
                                :large="large"
                                :small="small"
                                key="4">
                                <v-icon :large="large" :small="small">mdi-content-save</v-icon>
                            </v-btn>
                            <v-btn 
                                v-else-if="canEdit"
                                class="primary"
                                @click.stop="update"
                                dark
                                icon 
                                :large="large"
                                :small="small"
                                key="5">
                                <v-icon :large="large" :small="small">mdi-check</v-icon>
                            </v-btn>
                        </v-slide-x-transition>
                    </v-bottom-navigation>
                    <v-overlay :value="isLoading" class="text-center">
                        <v-progress-circular indeterminate size="32" />
                        <p>Loading Signature</p>
                    </v-overlay>
                </v-card>
            </v-overlay>
        </div>
    </v-slide-x-transition>
</template>

<script>
export default {
    name: 'BT-Signature-Overlay',
    data: function() {
        return {
            isApproved: false,
            isLoading: false,
            modal: false,
            mSignerName: null,
            options: {
                penColor: this.color
            },
            originalData: null,
            showSignButtons: true,
            data: null
        }
    },
    props: {
        block: {
            type: Boolean,
            default: false
        },
        buttonClass: {
            type: String,
            default: null
        },
        canEdit: {
            type: Boolean,
            default: true
        },
        canSave: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'black'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        fullscreen: {
            type: Boolean,
            default: false
        },
        getOnOpenAsync: {
            type: Function,
            default: null
        },
        height: {
            type: String,
            default: '400px'
        },
        icon: {
            type: String,
            default: 'mdi-draw'
        },
        large: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        onDoOpenAsync: {
            type: Function,
            default: null
        },
        show: {
            type: Boolean,
            default: false
        },
        showSignerName: {
            type: Boolean,
            default: false
        },
        signerName: {
            type: String,
            default: null
        },
        small: {
            type: Boolean,
            default: true
        },
        text: {
            type: String,
            default: null
        },
        transparent: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: '100%'
        },
        value: null,
    },
    methods: {
        close() {
            this.$emit('cancel');
            this.$emit('close');
            this.modal = false;
        },
        clear() {
            this.$refs.signaturePad.clearSignature();
            this.data = null;
        }, 
        ensureSignatureLoaded() {
            if (this.data != null) {
                this.isLoading = true;
                const interval = setInterval(() => {
                    if (this.$refs.signaturePad) {
                        this.loadSignature();
                        clearInterval(interval);
                    }
                }, 2000);
            }
        },
        loadSignature() {
            if (this.data != null) {
                var pArr = JSON.parse(this.originalData);
                if (pArr != null && pArr.length > 0) {
                    this.$refs.signaturePad?.fromData([{ color: this.color, points: pArr }]);
                }
            }
            else
            {
                this.clear();
                this.originalData = null;
            }

            this.isLoading = false;
        },
        async open() {
            if (this.onDoOpenAsync != null && !await this.onDoOpenAsync()) {
                return;
            }
            
            this.modal = true;

            if (this.getOnOpenAsync != null) {
                var res = await this.getOnOpenAsync();

                if (this.showSignerName) {
                    this.originalData = res.signature;
                    this.mSignerName = res.signerName;
                }
                else {
                    this.originalData = res;
                }
            }
            
            if (!this.originalData) {
                this.originalData = this.value;
            }
            
            if (!this.mSignerName && this.showSignerName) {
                this.mSignerName = this.signerName;
            }
            
            this.data = this.originalData;

            //if (this.data != null) {
            this.ensureSignatureLoaded();
            //}
            this.isApproved = false;

            this.$emit('open');
        },
        save() {
            this.originalData = this.data;
            var ret = this.showSignerName ? { signature: this.data, signerName: this.mSignerName } : this.data;
            this.$emit('input', this.data);
            this.$emit('change', this.data);
            this.$emit('ok', ret);
            this.$emit('close');
            this.modal = false;
        },
        update() {
            this.data = null;

            if (!this.$refs.signaturePad.isEmpty()) {
                var data = this.$refs.signaturePad.toData();
                var arr = [];
                data.forEach(d => {
                    d.points.forEach(p => {
                        arr.push({ x: p.x, y: p.y });
                    })
                })
                
                var minX = Math.min(...arr.map(x => x.x));
                var minY = Math.min(...arr.map(x => x.y));
                minX -= 1;
                minY -= 1;
                if (minX > 1) {
                    arr.forEach(x => { x.x -= minX });
                }
                if (minY > 1) {
                    arr.forEach(x => { x.y -= minY });
                }

                this.data = JSON.stringify(arr);

                this.isApproved = true;
            }
        }
    }   
}
</script>

<style scoped>
/* @media screen and (min-aspect-ratio: 13/9) {
  .sign-overlay {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: inherit;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}
@media screen and (min-width: 1292px) {
  .sign-overlay {
    transform: none;
    transform-origin: none;
    height: 100vh;
    width: 100%;
    overflow-x: none;
    position: relative;
  }
} */
</style>